@tailwind base;
@tailwind components;
@tailwind utilities;
* {
    scrollbar-width: auto;
    scrollbar-color: #1a191a #ffffff;
  }
  body {
    font-family: 'Poppins', sans-serif;
  }
  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff00;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #3f3e3f;
    border-radius: 10px;
    border: 3px solid #ffffff00;
  }

  
  .typed-out {
    /* display: block; */
    border-right: .15em solid #F24617;
    /* white-space: nowrap; */
    animation: typing 1s steps(20, end) forwards, blinking .8s infinite;
    width: fit-content;
  }
  @keyframes typing {
    from { width: 0 }
    to { width: fit-content }
  }
  @keyframes blinking {
    from { border-color: transparent }
    to { border-color: #F24617; }
  }

  .no-tailwind{
    all: unset;
  }



  @keyframes blink {
    50% {
      fill: transparent;
    }
  }
  
  .dot {
    animation: 1s blink infinite;
    fill: #F24617;
  }
  
  .dot:nth-child(2) {
    animation-delay: 250ms;
  }
  
  .dot:nth-child(3) {
    animation-delay: 500ms;
  }
  
  .loader {
    background-color: #F7F4F4;
    color: #F24617;
  }
  